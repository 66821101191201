import React from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

import { SERVICES } from '@/referentials/routes';

import MM2xSG from '@/assets/img/logos-clients/light-mode/mm2xsaintgobain.svg';
import MM2xSGDark from '@/assets/img/logos-clients/dark-mode/mm2xsaintgobain.svg';
import ImpulsexCharrette from '@/assets/img/logos-clients/light-mode/impulsexcharrette.svg';
import ImpulsexCharretteDark from '@/assets/img/logos-clients/dark-mode/impulsexcharrette.svg';

import Layout from '@/components/Layout';
import ConstructechHero from '@/components/Constructech/ConstructechHero';
import CtaBlock from '@/components/CtaBlock';
import SubContent from '@/components/SubContent';
import TitleText from '@/components/TitleText';
import LinkItem from '@/components/LinkItem';

export default function Methodology({ data }) {
  const { t } = useTranslation();

  return (
    <Layout
      title="Constructech"
      description="Le charrette.club design, code et fait grandir des produits digitaux innovants."
    >
      <ConstructechHero />
      <SubContent>
        <TitleText title={t('storyTitle')} />
        <p className="subcontent">{t('storyContent')}</p>
        <LinkItem
          className="font-heading text-lg lg:text-3xl mt-12 mb-[200px]"
          title={t('downloadLink')}
          blank
          to="https://www.pwc.fr/fr/assets/files/pdf/2019/01/fr-pwc-innovation-et-btp-la-transformation-du-secteur.pdf"
        />
        <TitleText title={t('experienceTitle')} />
        <div className="my-[100px]">
          <div className="light-only">
            <MM2xSG />
          </div>
          <div className="dark-only">
            <MM2xSGDark />
          </div>
        </div>
        <p className="subcontent">{t('experienceContent1')}</p>
        <LinkItem
          className="font-heading text-lg lg:text-3xl mb-12 mt-12"
          title={t('experienceLink')}
          blank
          to="https://www.lemoniteur.fr/article/avec-la-start-up-mon-maitre-carre-saint-gobain-se-lance-sur-l-architecture-et-la-deco-interieure.521764"
        />
        <p className="subcontent mb-[200px]">{t('experienceContent2')}</p>
        <TitleText title={t('partnerTitle')} />
        <div className="my-[100px]">
          <div className="light-only">
            <ImpulsexCharrette />
          </div>
          <div className="dark-only">
            <ImpulsexCharretteDark />
          </div>
        </div>
        <p className="subcontent">{t('partnerContent1')}</p>
        <LinkItem
          className="font-heading text-lg lg:text-3xl my-12"
          title={t('partnerLink')}
          blank
          to="https://impulse-partners.com/"
        />
        <p className="subcontent mb-[200px]">{t('partnerContent2')}</p>
        <TitleText title={t('ourOfferTitle')} />
        <p className="subcontent">{t('ourOfferContent')}</p>
      </SubContent>
      <CtaBlock
        title={t('ctaBlockTitle')}
        to={SERVICES}
        linkTitle={t('ctaBlockLink')}
        emoji={{
          image: getImage(data.chat),
          alt: 'services icon',
        }}
      />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["constructech", "general"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    chat: file(relativePath: { eq: "chat.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, placeholder: BLURRED)
      }
    }
  }
`;
